import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, Menu } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { Link, navigate } from 'gatsby';
import { ResellersCard } from '../../components/reseller/reseller-card';
import {
  resellersSelector,
  updateResellers,
} from '../../state/reducers/resellers';
import Reseller from '@myneva-portals/models/src/models/reseller/reseller';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { resellerService } from '@myneva-portals/services/src/services';
import PageKey from '@myneva-portals/models/src/models/page-key';
import {
  Activate,
  Deactivate,
  PenFilled,
  TableRows,
  TrashCan,
} from 'front-components/src/components/icons';
import {
  PrimaryButton,
  ErrorTimeout,
  LoadingWrapper,
  MenuItem,
  RowAction,
  DeleteModal,
  EmptyGroup,
  LinkButton,
  showToast,
} from 'front-components/src/components';
import { ContentBar } from '@myneva-portals/projects/src/components';
import {
  defaultErrorToast,
  redirectErrorToast,
} from '../../components/sections/static-data/default-error-toast';

export const ResellerContent: React.FC = () => {
  const useStyles = makeStyles(
    (theme: Theme) =>
      createStyles({
        Link: {
          textDecoration: 'none',
          float: 'left',
          marginLeft: '65%',
          marginTop: '40px',
        },
        ResellersContainer: {
          boxSizing: 'border-box',
          height: '93%',
          padding: '48px 56px 48px 56px',
          backgroundColor: theme.palette.background.default,
        },
        popoverActionRoot: {
          borderRadius: '16px',
          boxShadow: '0 4px 24px 0 rgba(4, 4, 5, 0.24)',
        },
        link: {
          textDecoration: 'none',
        },
        dialogContent: {
          fontFamily: 'Inter',
          display: 'flex',
          paddingBottom: '40px',
        },
        textWarning: {
          fontWeight: 'bold',
          lineHeight: '36px',
          fontSize: '18px',
          marginLeft: '10px',
        },
        dialogIcon: {
          height: '36px',
          width: '36px',
        },
        textSpacing: {
          marginBottom: '32px',
        },
        emptyGroupContainer: {
          margin: 'auto',
        },
      }),
    { name: 'ResellerContent' }
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toastConfig = defaultErrorToast();
  const redirectToastConfig = redirectErrorToast();

  const [isSessionError, setIsSessionError] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [resellerStatus, setResellerStatus] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const { breadcrumbs } = useSelector(navigationSelector);
  const { resellers } = useSelector(resellersSelector);

  const { isLoading, error, data, refetch } = useQuery(
    'getResellersQuery',
    () => resellerService.getResellers().then((data: Reseller[]) => data),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const { mutate: toggleResellerStatus } = useMutation(
    () =>
      resellerService
        .toggleResellerStatus(selectedId, resellerStatus)
        .then(() => refetch()),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const handleToggleStatus = async () => {
    toggleResellerStatus();
  };

  useEffect(() => {
    if (data) {
      dispatch(updateResellers(data));
    }
  }, [data]);

  useEffect(() => {
    console.log(error);
    console.log(error?.response);
  }, [error]);

  const handleClick = (event, reseller) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(reseller.id);
    setResellerStatus(reseller.isActive);
  };

  const handleEdit = () => {
    navigate(`/resellers/${selectedId}`, { state: { isEdit: true } });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDetails = () => {
    navigate(`/resellers/${selectedId}`, { state: { isEdit: false } });
  };

  const handleDelete = (index: number) => {
    setOpenDeleteDialog(true);
    setSelectedId(index);
  };

  const { mutate: deleteResellerMutation } = useMutation(
    (userID: number) =>
      resellerService
        .deleteReseller(userID)
        .then(() => handleDeleteDialogClose())
        .then(() => refetch()),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const deleteReseller = async () => {
    const userID: number = selectedId;
    deleteResellerMutation(userID);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setSelectedId(null);
  };

  const actionRows: RowAction[] = [
    {
      name: t('DETAILS'),
      actionHandle: handleDetails,
      icon: <TableRows />,
      cyName: 'reseller-core-action-details-button',
    },
    {
      name: resellerStatus ? t('DEACTIVATE') : t('ACTIVATE'),
      actionHandle: handleToggleStatus,
      icon: resellerStatus ? <Deactivate /> : <Activate />,
      cyName: `reseller-core-action-${
        resellerStatus ? 'deactivate' : 'activate'
      }-button`,
    },
    {
      name: t('EDIT'),
      actionHandle: handleEdit,
      icon: <PenFilled />,
      cyName: 'reseller-core-action-edit-button',
    },
    {
      name: t('DELETE'),
      actionHandle: handleDelete,
      icon: <TrashCan />,
      cyName: 'reseller-core-action-delete-button',
    },
  ];

  const actionPanel = (
    <div>
      <Link
        className={classes.link}
        to={PageKey.RESELLERS_ADD}
        data-cy={'reseller-add-reseller-button'}
      >
        <PrimaryButton size="small" label={t('ADD_RESELLER')} />
      </Link>
    </div>
  );

  const content = (
    <>
      <ContentBar
        translation={t}
        breadcrumbsItems={breadcrumbsUtils.getBreadcrumbs(breadcrumbs)}
        actionPanelContent={actionPanel}
      />

      <div className={classes.ResellersContainer}>
        <Grid container spacing={3}>
          {resellers?.length > 0 ? (
            resellers.map((reseller: Reseller, index: number) => {
              return (
                <Grid
                  key={`reseller-card-${index}`}
                  item
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}
                >
                  <div>
                    <ResellersCard
                      id={reseller.id}
                      title={reseller.name}
                      avatar={reseller.avatar}
                      invocing={reseller.invocing}
                      intAccNumber={reseller.intAccNumber}
                      onClick={(event) => handleClick(event, reseller)}
                      isFav={reseller.isFav}
                      isActive={reseller.isActive}
                    >
                      <Link
                        to={`/resellers/${reseller.id}`}
                        className={classes.Link}
                        data-cy={'resellers-tile-details-button'}
                      >
                        <LinkButton />
                      </Link>
                    </ResellersCard>
                  </div>
                  <Menu
                    disableScrollLock={false}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl && selectedId == reseller.id)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    PopoverClasses={{
                      paper: classes.popoverActionRoot,
                    }}
                  >
                    {actionRows.map((action: RowAction, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          if (action.actionHandle) {
                            action.actionHandle(selectedId);
                            handleClose();
                          }
                        }}
                        name={action.name}
                        icon={action.icon}
                        cyName={action.cyName}
                      />
                    ))}
                  </Menu>
                </Grid>
              );
            })
          ) : (
            <div className={classes.emptyGroupContainer}>
              <EmptyGroup
                header={t('NO_RESELLERS')}
                text={t('EMPTY_ADD_RESELLER')}
                buttonLabel={t('EMPTY_ADD_RESELLER_BUTTON')}
                onButtonClick={() => navigate(PageKey.RESELLERS_ADD)}
              />
            </div>
          )}
        </Grid>
        <DeleteModal
          open={openDeleteDialog}
          handleDeleteDialogClose={handleDeleteDialogClose}
          dataCy={'reseller-core-delete-modal'}
          header={t('RESELLER_DELETE_TEXT2')}
          content={t('RESELLER_DELETE_TEXT1')}
          onClickPrimaryButton={deleteReseller}
          labelPrimaryButton={t('DELETE_PERMANENTLY')}
          onClickSecondaryButton={handleDeleteDialogClose}
          labelSecondaryButton={t('CANCEL')}
        />
      </div>
    </>
  );
  const errorComponent = (
    <ErrorTimeout
      header={t('TIMEOUT_ERROR')}
      text={t('TIMEOUT_ERROR_MSG')}
      buttonLabel={t('REFETCH_BUTTON_LABEL')}
      onButtonClick={() => refetch()}
    />
  );

  return (
    <div>
      <LoadingWrapper
        isLoading={isLoading || isSessionError || isSessionError}
        content={error ? errorComponent : content}
        useLineProgress={true}
      />
    </div>
  );
};
