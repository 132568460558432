import React from 'react';
import { createStyles, makeStyles, Theme, Chip } from '@material-ui/core';
import {
  Paragraph2Regular,
  Subheading2,
  Label,
  Title,
} from 'front-components/src/themes';
import { useTranslation } from 'react-i18next';
import {
  LabelAndText,
  TextOverflow,
  UserAvatar,
  Card,
  MoreOptionsButton,
} from 'front-components/src/components';
import { getThemeMode } from 'front-components/src/tools/theme-extensions';
export interface ResellersCardProps {
  id: number;
  title: string;
  onClick?: (event) => void;
  invocing: string;
  intAccNumber: string;
  children?: React.ReactNode;
  isFav?: boolean;
  avatar?: string;
  isActive?: boolean;
}

export const ResellersCard: React.FC<ResellersCardProps> = (
  props: ResellersCardProps
) => {
  const useStyles = makeStyles(
    (theme: Theme) =>
      createStyles({
        cardLayout: {
          borderRadius: '0 8px 8px',
          padding: '8px 16px 24px !important',
          backgroundColor: theme.palette.background.paper,
          margin: 0,
          overflow: 'auto',
        },
        cardTop: {
          marginLeft: '1px',
          width: 114.5,
          height: 5,
          borderBottom: '25px solid',
          borderBottomColor: theme.palette.background.paper,
          borderRight: ' 20px solid transparent',
          zIndex: 99,
        },
        cardTopLeft: {
          width: '115px',
          height: '30px',
          backgroundColor: theme.palette.background.paper,
          margin: '0',
          border: '1px solid',
          borderColor: theme.palette.background.paper,
          borderRadius: '10px 10px 0 0',
          overflow: 'visible',
          marginTop: '-15px',
          marginLeft: '-1px',
        },
        cardTopRight: {
          width: '10px',
          position: 'absolute',
          height: '30px',
          backgroundColor: theme.palette.background.default,
          margin: '0',
          border: '0.1px solid',
          borderColor: theme.palette.background.default,
          borderRadius: '0 0 0 10px',
          marginTop: '-33.9px',
          marginLeft: '130px',
          zIndex: 99,
        },
        cardTopBasement: {
          width: '10px',
          height: '10px',
          backgroundColor: theme.palette.background.paper,
          position: 'absolute',
          marginTop: '-6px',
          marginLeft: '129px',
          zIndex: 0,
        },
        cardCointainer: {
          margin: '28px 28px',
        },
        Paragraph2Regular: {
          ...Paragraph2Regular,
        },
        Label: {
          ...Label,
        },
        LabelSpaceing: {
          ...Label,
          marginTop: theme.spacing(2),
        },
        Title: {
          ...Title,
        },
        AccoutingWrapper: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        },
        Subheading2: {
          ...Subheading2,
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(0),
          color: getThemeMode(theme).text.text01,
        },
        activeChip: {
          border: 'solid 1px',
          borderColor: getThemeMode(theme).status.active.border,
          backgroundColor: getThemeMode(theme).status.active.background,
          color: getThemeMode(theme).status.active.label,
          fontFamily: 'Inter',
          fontWeight: 600,
        },
        deactivatedChip: {
          border: 'solid 1px',
          borderColor: getThemeMode(theme).status.inactive.border,
          backgroundColor: getThemeMode(theme).status.inactive.background,
          color: getThemeMode(theme).status.inactive.label,
          fontFamily: 'Inter',
          fontWeight: 600,
        },
        chipLabelClass: {
          marginTop: '12px',
          marginBottom: '12px',
        },
      }),
    { name: 'ResellerCardContent' }
  );

  const classes = useStyles();
  const { t } = useTranslation();

  const chipClass = props.isActive
    ? classes.activeChip
    : classes.deactivatedChip;
  const status = props.isActive ? t('ACTIVE') : t('DEACTIVATED');

  return (
    <div className={classes.cardCointainer} data-cy={'resellers-tile'}>
      <div className={classes.cardTop}>
        <div className={classes.cardTopLeft}></div>
        <div className={classes.cardTopRight}></div>
        <div className={classes.cardTopBasement}></div>
      </div>
      <div className={classes.cardLayout}>
        <Card
          headerComponent={
            <UserAvatar name={props.title} avatar={props.avatar} />
          }
          actionButtons={
            <>
              {/* <StarButton
                isFav={props.isFav}
                cyName={'resellers-tile-favourite-button'}
              /> 
              According to desciption provided to MC-542, this feature won’t be in this MVP, but it will be implemented and extended in the future.
              */}
              <MoreOptionsButton
                onClick={(event) => props.onClick(event)}
                cyName={'resellers-tile-action-button'}
              />
            </>
          }
        >
          <div className={classes.Subheading2}>
            <TextOverflow>{props.title}</TextOverflow>
          </div>

          <Chip
            label={status}
            size="small"
            classes={{ root: chipClass, label: classes.chipLabelClass }}
          />
          <div className={classes.AccoutingWrapper}>
            <LabelAndText label={t('INVOICING')} text={props.invocing} />
          </div>
          <LabelAndText
            label={t('INTERNAL_ACCOUNTING_NUMBER')}
            text={props.intAccNumber}
          />

          {props.children}
        </Card>
      </div>
    </div>
  );
};
